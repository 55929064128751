import { StoreApi, createStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { logger, isClient, defaultStorage, sliceResetFns } from 'utils/store'
import { defaultSession, type SessionDataType } from 'lib/session/config'
import { type AddressType } from 'app/account/address/_utils/responses'

export type UserType = {
  address_exist: boolean
  check_fraud: boolean
  email: string
  first_name: string
  id: number
  is_active: boolean
  is_eighteen_plus: boolean
  is_fraud: boolean
  is_new_buyer: boolean
  is_twenty_one: boolean
  ktp_exist: boolean
  last_name: string
  phone_number: string
  pin_exist: boolean
  pin_need_reset: boolean
  referral_code: string
  server_time: string
  authorities: Array<{ name: string }>
}

export type SessionStateType = SessionDataType

export type SessionActionType = {
  updateToken: (token: Nullable<string>) => void
  updateUser: (user: Nullable<UserType>) => void
  updateSessionToken: (sessionToken: string) => void
  updateCustomerAddress: (address: AddressType) => void
  clearUser: () => void
  setIsShowAstroAppBanner: (payload: SessionStateType['isShowAstroAppBanner']) => void
  setPaymentActivationSource: (source: SessionStateType['paymentActivationSource']) => void
  updateUserSessionStore: (payload: SessionStateType) => void
  resetUserState: () => void
}

export const sessionStoreDefaultState: SessionStateType = {
  ...defaultSession,
}

export type UserSliceType = SessionStateType & SessionActionType

export const SLICE_NAME = 'user'

const getPersistedStoreByKeySession = (state: UserSliceType) =>
  Object.fromEntries(
    Object.entries(state).filter(
      // Excluded keys
      ([key]) => !['sessionToken', 'token', 'isLoggedIn', 'customerAddress', 'user'].includes(key),
    ),
  )

let instance: StoreApi<UserSliceType>

const createSessionStore = (initState = {}) =>
  createStore<UserSliceType>()(
    logger(
      persist(
        immer((set) => {
          sliceResetFns.add(() => {
            set(() => sessionStoreDefaultState)
          })
          return {
            ...sessionStoreDefaultState,
            ...initState,
            updateToken: (token) =>
              set((state) => {
                state.token = token
              }),
            updateUser: (user) =>
              set((state) => {
                state.user = user
              }),
            updateSessionToken: (sessionToken) =>
              set((state) => {
                state.sessionToken = sessionToken
              }),
            clearUser: () => {
              set((state) => {
                state.token = null
                state.user = null
              })
            },
            updateCustomerAddress: (address) => {
              set((state) => {
                state.customerAddress = address
              })
            },
            setIsShowAstroAppBanner: (payload) => {
              set((state) => {
                state.isShowAstroAppBanner = payload
              })
            },
            setPaymentActivationSource: (payload) => {
              set((state) => {
                state.paymentActivationSource = payload
              })
            },
            updateUserSessionStore: (payload) => {
              set((state) => ({
                ...state,
                ...payload,
              }))
            },
            resetUserState: () =>
              set(() => ({
                ...sessionStoreDefaultState,
              })),
          }
        }),
        {
          name: SLICE_NAME,
          storage: isClient()
            ? createJSONStorage(() => localStorage)
            : createJSONStorage(() => defaultStorage),
          partialize: (state) => getPersistedStoreByKeySession(state),
        },
      ),
      SLICE_NAME,
    ),
  )

export const setSessionInstanceStore = (newInstance: StoreApi<UserSliceType>) => {
  instance = newInstance
}

export const getSessionStore = () => instance

export type SessionStoreType = ReturnType<typeof createSessionStore>

export { createSessionStore }
