import Backdrop, { BackdropProps as MuiBackdropProps } from '@mui/material/Backdrop'
import { styled } from '@mui/material/styles'

export type BackdropPropsType = MuiBackdropProps

const AstrBackdrop = styled(Backdrop)<BackdropPropsType>`
  color: white;
`

export default AstrBackdrop
