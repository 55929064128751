import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery, useMutation } from '@tanstack/react-query'
import { dateFormat } from '@astronautsid/wpe-utils/helpers/formatDate'
import { toast } from 'react-hot-toast'
import type { AxiosError } from '@astronautsid/wpe-utils'

import useUserStore from 'store/user'
import useCategoryCatalogueStore from 'store/category&catalogue/category&catalogueSlice'
import { customerDetailInfoService, editCustomerService } from 'app/account/_utils/services'
import type { EditRequestType } from 'app/account/_utils/response'
import { getCurrentUserService, putUserSessionService } from 'app/login/_utils/services'
import { useLoadingStore } from 'components/LoadingOverlay'

import { confirmationAgeSchema } from '../_utils/request'
import type { ConfirmationAgeFormRequestType } from '../_utils/request'

function useBottomSheetConfirmationAge() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    resetField,
  } = useForm<ConfirmationAgeFormRequestType>({
    resolver: zodResolver(confirmationAgeSchema),
    mode: 'all',
  })

  const { token, updateUser } = useUserStore((state) => state)
  const { isShowConfirmationAge, setIsShowConfirmationAge } = useCategoryCatalogueStore()

  const { setLoading } = useLoadingStore()

  const { data: userData, isLoading } = useQuery({
    queryKey: ['user-detail', token],
    queryFn: () => customerDetailInfoService(String(token)),
    enabled: !!token,
  })

  useEffect(() => {
    setValue('firstname', userData?.data?.first_name || '')
    setValue('lastname', userData?.data?.last_name || '')
    setValue('nik', userData?.data?.ktp || '')

    if (userData?.data?.date_of_birth) {
      setValue('dob', new Date(userData?.data?.date_of_birth))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const handleResetNik = () => {
    resetField('nik')
  }

  const handleBottomSheetDateState = () => {
    setIsShowConfirmationAge(!isShowConfirmationAge)
  }

  const { mutateAsync } = useMutation({
    mutationFn: editCustomerService,
    mutationKey: ['account-edit'],
    onSuccess: async () => {
      const { data: newUser } = await getCurrentUserService()

      await putUserSessionService({
        user: newUser,
      })

      updateUser(newUser)
      setIsShowConfirmationAge(false)

      window.location.reload()
    },
    onError: (error) => {
      if (error instanceof Error) {
        toast.error(error.message)
      } else {
        const err = error as AxiosError<{ error: { message: string } }>
        toast.error(String(err?.response?.data?.error?.message))
      }
    },
  })

  const onSubmit = async (data: ConfirmationAgeFormRequestType) => {
    const formData: EditRequestType = {
      first_name: data.firstname,
      last_name: data.lastname,
      ktp: data.nik,
      birthdate: dateFormat({ date: data.dob, format: 'DD-MM-YYYY' }),
    }

    try {
      setLoading(true)
      await mutateAsync(formData)
    } catch {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    control,
    watch,
    handleResetNik,
    isLoading,
    handleBottomSheetDateState,
  }
}

export default useBottomSheetConfirmationAge
