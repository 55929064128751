/* eslint-disable @typescript-eslint/naming-convention */
import client from 'config/apiServiceConfig'

import type {
  CustomerUserInfoResponseType,
  CustomerUserAddressesResponseType,
  CustomerDetailInfoResponseType,
  EditRequestType,
  UserDeactivateContentResponseType,
  UserDeactivateResponseType,
} from './response'

const { Get, Put, Post } = client

export const customerUserInfoService = async (token: string) =>
  Get<CustomerUserInfoResponseType, true>({
    url: '/api/current',
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-APP-VERSION': undefined,
      },
    },
  })

export const customerUserAddressesService = async (token: string) =>
  Get<CustomerUserAddressesResponseType, true>({
    url: '/api/customer-address',
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })

export const customerDetailInfoService = async (token: string) =>
  Get<CustomerDetailInfoResponseType, true>({
    url: '/accounts/v1/customer/detail',
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-APP-VERSION': undefined,
      },
    },
  })

export const editCustomerService = async (payload: EditRequestType) =>
  Put<CustomerUserInfoResponseType, true>({
    url: '/api/customer',
    data: payload,
  })

export const userDeactivateContentService = async (token: string) =>
  Get<UserDeactivateContentResponseType, true>({
    url: '/api/customer/deactivate-content',
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })

export const userDeactivateService = async (token: string) =>
  Post<UserDeactivateResponseType, true>({
    url: '/api/customer/deactivate',
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })
