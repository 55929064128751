'use client'

import { PropsWithChildren, createContext, useContext, useRef } from 'react'
import { useStore } from 'zustand'

import { usePathname } from 'next/navigation'
import usePrevious from 'hooks/usePrevious'
import {
  createSessionStore,
  sessionStoreDefaultState,
  setSessionInstanceStore,
  type SessionStateType,
  type UserSliceType,
  type SessionStoreType,
} from './store'

export type SessionStoreProviderPropsType = PropsWithChildren<{
  initState: SessionStateType
}>

export const SessionStoreContext = createContext<SessionStoreType | null>(null)

export const SessionStoreProvider = ({ children, initState }: SessionStoreProviderPropsType) => {
  const storeRef = useRef<SessionStoreType>()

  const pathname = usePathname()

  const prevPathname = usePrevious(pathname)

  if (!storeRef.current) {
    storeRef.current = createSessionStore({ ...sessionStoreDefaultState, ...initState })
    setSessionInstanceStore(storeRef.current)
  }

  if (prevPathname !== pathname) {
    setSessionInstanceStore(storeRef.current)
  }

  return (
    <SessionStoreContext.Provider value={storeRef.current}>{children}</SessionStoreContext.Provider>
  )
}

export const useSessionStoreContext = () => useContext(SessionStoreContext)

const useSessionStore = <T,>(selector: (store: UserSliceType) => T): T => {
  const sessionStoreContext = useSessionStoreContext()

  if (!sessionStoreContext) {
    throw new Error(`useSessionStore must be use within SessionStoreProvider`)
  }

  return useStore(sessionStoreContext, selector)
}

export default useSessionStore
