'use client'

import { create } from 'zustand'
import { LoadingOverlay as LoadingOverlayAstroUI } from 'components/AstroUI'

type LoadingStoreType = {
  loading: boolean
  setLoading: (loading: boolean) => void
}

export const useLoadingStore = create<LoadingStoreType>((set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),
}))

export const LoadingOverlay = () => {
  const { loading } = useLoadingStore()

  return <LoadingOverlayAstroUI isLoading={loading} />
}
