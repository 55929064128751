import { Calendar, ChevronDown } from '@astronautsid/wpe-icons'
import { css, styled, useTheme } from '@mui/material'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'

export type FieldsetPropsType = {
  date: string
  placeholder?: string
  disabled?: boolean
  error?: boolean
  handleOpen: (event: React.MouseEvent<HTMLDivElement>) => void
}

const BoxStyled = styled(Box)<{ disabled?: boolean; error?: boolean }>`
  position: relative;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.strokeColor.default};
  background-color: ${({ theme }) => theme.palette.bgColor.light};
  border-radius: 8px;
  overflow: hidden;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.palette.bgColor.disableD};
      cursor: default;
      span {
        color: ${theme.palette.textColor.disable};
      }
    `}

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.palette.strokeColor.error};
    `}
`

const Fieldset = ({
  date,
  placeholder = 'Pilih Tanggal',
  disabled,
  error,
  handleOpen,
}: FieldsetPropsType) => {
  const theme = useTheme()
  const { textColor, iconColor } = theme.palette

  return (
    <BoxStyled
      className="fieldset-datepicker"
      onClick={handleOpen}
      disabled={disabled}
      error={error}
    >
      <Box display="flex" alignItems="center" gap="8px">
        <Box
          height={42}
          bgcolor={theme.palette.bgColor.lightPrimary}
          p={theme.spacing('md')}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Calendar type="solid" size={20} />
        </Box>
        <Box width="100%">
          <Typography
            variant="paragraph-small"
            color={date ? textColor.primaryDark : textColor.placeholder}
          >
            {date || placeholder}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          ml={theme.spacing('smd')}
          mr={theme.spacing('lg')}
        >
          <ChevronDown color={!disabled ? iconColor.default : iconColor.disable} size={20} />
        </Box>
      </Box>
    </BoxStyled>
  )
}

export default Fieldset
