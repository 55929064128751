import { type SessionOptions } from 'iron-session'

import { DEFAULT_GLOBAL_LOCATION_ID, SESSION_COOKIE_NAME } from 'config/constants'
import { type UserType } from 'store/user'
import { type AddressType } from 'app/account/address/_utils/responses'

export type SessionDataType = {
  token: Nullable<string>
  isLoggedIn: boolean
  user: Nullable<UserType>
  customerAddress: AddressType
  paymentActivationSource: 'account' | 'cart' | null
  sessionToken: string
  isShowAstroAppBanner: boolean
}

const defaultAddress = {
  address: '',
  city: '',
  customer_gift_id: '',
  customer_id: 0,
  default_address: false,
  description: '',
  district: '',
  full_name: '',
  gift_notes: '',
  id: 0,
  is_active: false,
  is_default_express: false,
  is_location_type_vm: false,
  label_address: '',
  latitude: '',
  longitude: '',
  location_close_reason: {
    reason_title: '',
    reason_content: '',
  },
  location_id: DEFAULT_GLOBAL_LOCATION_ID,
  location_megahub_id: 0,
  location_megahub_quadrant_id: 0,
  location_open: '',
  location_status: false,
  phone: '',
  place_name: '',
  province: '',
  sender_name: '',
  service_level: {
    available: false,
    sla_id: 0,
    sla_display_name: '',
    sla_max_duration: 0,
    sla_min_duration: 0,
    sla_name: '',
    sla_total_duration: 0,
  },
  service_level_id: 0,
  service_level_name: '',
  service_level_regular: '',
  service_level_super: '',
  service_level_total_duration: 0,
  shipping_instruction: '',
  sub_district: '',
  time_slot_id: 0,
  zip: '',
}

export const defaultSession: SessionDataType = {
  user: null,
  isLoggedIn: false,
  customerAddress: defaultAddress,
  sessionToken: '',
  token: null,
  /**
   * Belows are fields that are not exluded from persist storage.
   * The updated value will not sync from server (for client side purpose only).
   */
  paymentActivationSource: null,
  isShowAstroAppBanner: false,
}

export const MAX_AGE_SESSION_COOKIE = 60 * 60 * 24 * 90 // 90 days.

export const sessionOptions: SessionOptions = {
  password: process.env.SESSION_PASSWORD || 'mysecretpasswordyongkrumamen',
  cookieName: SESSION_COOKIE_NAME,
  cookieOptions: {
    secure: process.env.NODE_ENV === 'production',
    maxAge: MAX_AGE_SESSION_COOKIE - 60, // Expire cookie before the session expires
  },
  ttl: MAX_AGE_SESSION_COOKIE,
}

export const sealAndUnsealSessionOption = {
  password: sessionOptions.password,
  ttl: sessionOptions.ttl,
}

export const normalizeCustomerAddress = (customerAddress: AddressType | undefined) => {
  if (!customerAddress) {
    return defaultSession.customerAddress
  }

  return {
    ...customerAddress,
    location_id: customerAddress?.location_id || 0,
  }
}
