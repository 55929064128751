import React, { useEffect, useState } from 'react'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Button } from '@astronautsid/wpe-astro-ui/components/atoms/Button'

import debounce from 'debounce'

import BottomSheet from 'components/BottomSheet'

import Wheel from './Wheel'

type WheelBottomSheetPropsType = {
  open: boolean
  date: Date | null
  onClose: () => void
  onChange: (value: Date) => void
}

const month = [
  `Januari`,
  `Februari`,
  `Maret`,
  `April`,
  `Mei`,
  `Juni`,
  `Juli`,
  `Agustus`,
  `September`,
  `Oktober`,
  `November`,
  `Desember`,
]

const dates = Array.from({ length: 31 }, (_, i) => (i + 1).toString())

const generateYears = (): string[] => {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let year = currentYear; year >= currentYear - 100; year -= 1) {
    years.push(year.toString())
  }

  return years
}

const maxDays = (year: number, monthParam: number): number =>
  new Date(year, monthParam + 1, 0).getDate()

const WheelBottomSheet = ({ open, onClose, date, onChange }: WheelBottomSheetPropsType) => {
  const [selectedDate, setSelectedDate] = useState<number>(0)
  const [selectedMonth, setSelectedMonth] = useState<number>(0)
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear())

  const [initialDate, setInitialDate] = useState<Date>()

  const years = generateYears()

  const monthDebounceFunction = debounce(setSelectedMonth, 100)
  const yearDebounceFunction = debounce(setSelectedYear, 100)
  const dateDebounceFunction = debounce(setSelectedDate, 100)

  const yearIndex = years.indexOf(initialDate?.getFullYear().toString() || '')

  const handleMonth = (monthParam: number) => {
    const correctMonth = monthParam
    monthDebounceFunction(correctMonth)
  }

  const handleDate = (dateParam: number) => {
    dateDebounceFunction(dateParam + 1)
  }

  const handleYear = (year: number) => {
    const yearSelect = years[year]
    yearDebounceFunction(Number(yearSelect))
  }

  const handleSaveDate = () => {
    const newDate = new Date(selectedYear, selectedMonth, selectedDate)
    onChange(newDate)
  }

  useEffect(() => {
    if (date) {
      setInitialDate(date)
    }
  }, [date])

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Box display="flex" justifyContent="space-between" flexDirection="column" height="100%">
        <Box>
          <Box padding="16px 0">
            <Typography variant="headline-large">Tanggal Lahir</Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box
              width="100%"
              height={200}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="headline-small">Tanggal</Typography>
              <Wheel
                maxDate={maxDays(selectedYear, selectedMonth)}
                onChange={(e) => handleDate(e as number)}
                content={dates}
                initialValue={(initialDate?.getDate() || 1) - 1} // why -1? because the initial value is based on index not on actual value
              />
            </Box>
            <Box
              width="100%"
              height={200}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="headline-small">Bulan</Typography>
              <Wheel
                onChange={(e) => handleMonth(e as number)}
                content={month}
                initialValue={initialDate?.getMonth() || 0}
              />
            </Box>
            <Box
              width="100%"
              height={200}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="headline-small">Tahun</Typography>
              <Wheel
                onChange={(e) => handleYear(e as number)}
                content={years}
                initialValue={yearIndex !== -1 ? yearIndex : 0}
              />
            </Box>
          </Box>
        </Box>

        <Box paddingTop="16px">
          <Button fullWidth onClick={handleSaveDate}>
            Simpan
          </Button>
        </Box>
      </Box>
    </BottomSheet>
  )
}

export default WheelBottomSheet
