import { AxiosError } from '@astronautsid/wpe-utils'
import { log } from '@astronautsid/wpe-utils/helpers/datadog'

import { DATADOG_LOG_HTTP_CODE } from 'config/constants'
import { getParsedErrorResponse, type APIErrorResponseType } from 'utils/errorMessage'

export const axiosErrorLoggerInterceptor = (err: AxiosError) => {
  const errorStatusCodes = DATADOG_LOG_HTTP_CODE ? DATADOG_LOG_HTTP_CODE.split(',') : []

  const allowLogging =
    err.response &&
    !!errorStatusCodes.length &&
    errorStatusCodes.includes(String(err.response.status))

  if (err.isAxiosError && allowLogging) {
    const httpMethod = err.config?.method || ''
    const httpUrl = err.config?.url || ''
    const httpBody = err.config?.data || ''

    // Server was able to send us a response, so this is an API Error.
    if (err.response) {
      const message = getParsedErrorResponse(err as APIErrorResponseType)

      const bodyLogMessage = `${message}. ${httpMethod} ${httpUrl}`

      log.error(bodyLogMessage, {
        customInfo: {
          origin: 'network-interceptor',
          data: {
            method: httpMethod && httpMethod.toLocaleUpperCase(),
            url: httpUrl,
            body: httpBody,
            statusCode: err.response.status,
          },
        },
      })
    }
  }
}
