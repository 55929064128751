import MuiCircularProgress, {
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

export type CircularProgressPropsType = MuiCircularProgressProps

const CircularProgress = styled(MuiCircularProgress)<CircularProgressPropsType>``

export default CircularProgress
