// this component was directly copied and then edited from:
// https://codesandbox.io/p/sandbox/github/rcbyr/keen-slider-sandboxes/tree/v6/ios-like-picker/date-time/react-typescript?file=%2Fsrc%2FWheel.tsx%3A1%2C1-134%2C1

import 'keen-slider/keen-slider.min.css'

import React, { useRef, useEffect } from 'react'
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react.es'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

import debounce from 'debounce'

type WheelPropsType = {
  maxDate?: number
  initialValue?: number
  content: string[]
  onChange: (value: string | number) => void
}

const Wheel = ({ content, onChange, maxDate = 0, initialValue = 0 }: WheelPropsType) => {
  const maxSlides = content.length
  const maxDateRef = useRef(maxDate)
  const theme = useTheme()

  const options = useRef<KeenSliderOptions>({
    slides: {
      number: maxSlides,
      origin: `center`,
      perView: 3,
    },

    initial: initialValue,
    vertical: true,
    loop: true,
    detailsChanged: (s) => {
      onChange(s.track.details.rel)
    },
    rubberband: false,
    mode: 'free-snap',
  })

  const [sliderRef, slider] = useKeenSlider(options.current, [
    (sliderPlugin) => {
      const debouncedMove = debounce(sliderPlugin.moveToIdx, 300)

      sliderPlugin.on('animationEnded', () => {
        if (maxDateRef.current > 0 && sliderPlugin.track.details.rel > maxDateRef.current - 1) {
          debouncedMove(maxDateRef.current - 1)
        }
      })
      /* Cleanup */
      sliderPlugin.on('destroyed', () => {
        debouncedMove.clear()
      })
    },
  ])

  useEffect(() => {
    maxDateRef.current = maxDate
  }, [maxDate])

  useEffect(() => {
    if (slider.current && initialValue > 0) {
      slider.current?.moveToIdx(initialValue)
    }
  }, [initialValue, slider])

  if (content.length === 0) {
    return null
  }

  return (
    <Box
      display="block"
      height="100%"
      overflow="hidden"
      className="keen-slider"
      position="relative"
      width="100%"
      ref={sliderRef}
    >
      <Box
        style={{
          background: 'linear-gradient(rgb(255 255 255 / 90%) 0%, rgb(255 255 255 / 50%) 100%)',
        }}
        width="100%"
        height="calc(35% + 2px)"
        position="absolute"
        marginTop="-2px"
        zIndex={5}
        borderBottom={`1px solid ${theme.palette.strokeColor.subtle}`}
        left="0"
        id="wheel__top"
      />
      <Box
        display="flex"
        flexDirection="column"
        align-items="center"
        justifyContent="center"
        width="100%"
      >
        {content.map((value, idx) => (
          <Box
            alignItems="center"
            display="flex"
            width="100%"
            justifyContent="center"
            className="keen-slider__slide"
            key={`wheel-date-${idx + 1}`}
          >
            <Typography variant="body-default" color={theme.palette.textColor.primaryDark}>
              {value}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        style={{
          background: 'linear-gradient(rgb(255 255 255 / 90%) 0%, rgb(255 255 255 / 50%) 100%)',
        }}
        marginTop="2px"
        borderTop={`1px solid ${theme.palette.strokeColor.subtle}`}
        width="100%"
        height="calc(35% + 2px)"
        position="absolute"
        left="0"
        bottom="0"
        zIndex={5}
        id="wheel__bottom"
      />
    </Box>
  )
}

export default Wheel
