import { AxiosError } from '@astronautsid/wpe-utils/helpers/axiosIntance'

export type APIErrorResponseType = AxiosError<{
  error_message?: string
  message?: string
  error?: {
    message?: string
  }
}>

export const getParsedErrorResponse = (error: APIErrorResponseType) => {
  if (error?.response) {
    const message =
      error?.response?.data?.error?.message ||
      error?.response?.data?.message ||
      error?.response?.data?.error_message

    return message || `Failed with status code ${error.response?.status}`
  }

  return 'Terjadi kesalahan'
}
