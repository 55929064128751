import { z } from 'zod'

export const confirmationAgeSchema = z.object({
  firstname: z.coerce.string().trim().min(1, { message: 'nameRequired' }),
  lastname: z.coerce.string().trim(),
  nik: z.coerce
    .string()
    .min(16, { message: 'nikMin' })
    .refine((data) => !!+data, {
      message: 'nikType',
    }),
  dob: z.coerce.date(),
})

export type ConfirmationAgeFormRequestType = z.infer<typeof confirmationAgeSchema>
