import client from 'config/apiServiceConfig'
import { type SessionDataType } from 'lib/session/config'
import type { GenerateOtpRequestType, ValidateOtpRequestType } from './requests'
import type {
  CustomerLoginResponseType,
  GenerateOtpResponseType,
  ValidateOtpResponseType,
  CurrentUserResponseType,
} from './responses'

const { Get, Post, Put } = client

const correctionHeaders = {
  headers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'X-APP-VERSION': undefined,
  },
}

export const customerLoginService = async (phoneNumber: string) =>
  Get<CustomerLoginResponseType, true>({
    url: `/accounts/v1/customer/login?phone_number=${phoneNumber}`,
    config: correctionHeaders,
  })

export const generateOtpService = async (payload: GenerateOtpRequestType) =>
  Post<GenerateOtpResponseType, true>({
    url: '/api/otp/generate',
    data: payload,
    config: correctionHeaders,
  })

export const generateOtpWAService = async (payload: GenerateOtpRequestType) =>
  Post<GenerateOtpResponseType, true>({
    url: '/api/otp/generate_wa',
    data: payload,
    config: correctionHeaders,
  })

export const generateOtpSMSService = async (payload: GenerateOtpRequestType) =>
  Post<GenerateOtpResponseType, true>({
    url: '/api/otp/generate_sms',
    data: payload,
    config: correctionHeaders,
  })

export const validateOtpService = async (payload: ValidateOtpRequestType) =>
  Post<ValidateOtpResponseType, true>({
    url: '/api/otp/validate',
    data: payload,
    config: correctionHeaders,
  })

export const getCurrentUserService = async () =>
  Get<CurrentUserResponseType, true>({
    url: '/api/current',
    config: correctionHeaders,
  })

export const updateUserSessionService = async (payload: Partial<SessionDataType>) =>
  Post({
    url: '/api/session',
    data: payload,
    config: {
      baseURL: '',
    },
  })

export const putUserSessionService = async (payload: Partial<SessionDataType>) =>
  Put({
    url: '/api/session',
    data: payload,
    config: {
      baseURL: '',
    },
  })

export const logoutUserService = async () =>
  Get({
    url: '/api/session?action=logout',
    config: {
      baseURL: '',
    },
  })
