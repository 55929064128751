import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { logger } from 'utils/store'

export type CategoryCatalogueSliceType = {
  isError: boolean
  isShowConfirmationAge: boolean
  categoryLevelName: string
  setError: (param: boolean) => void
  setIsShowConfirmationAge: (param: boolean) => void
  setCategoryLevelName: (param: string) => void
}

export const categoryCatalogueState = {
  isError: false,
  isShowConfirmationAge: false,
  isEmptySingleCategory: false,
  categoryLevelName: '',
}

const SLICE_NAME = 'categoryCatalogueStore'

const useCategoryCatalogueStore = create<CategoryCatalogueSliceType>()(
  logger(
    immer((set) => ({
      ...categoryCatalogueState,
      setError: (payload) =>
        set(() => ({
          isError: payload,
        })),
      setIsShowConfirmationAge: (payload) =>
        set(() => ({
          isShowConfirmationAge: payload,
        })),
      setCategoryLevelName: (payload) =>
        set(() => ({
          categoryLevelName: payload,
        })),
    })),
    SLICE_NAME,
  ),
)

export default useCategoryCatalogueStore
