import React, { useState, useEffect } from 'react'
import { dateFormat } from '@astronautsid/wpe-utils/helpers/formatDate'
import WheelBottomSheet from './_components/WheelBottomSheet'
import Fieldset from './_components/Fieldset'

export type DatePickerPropsType = {
  date?: Date
  error?: boolean
  onChange: (date: Date) => void
  onOpen?: () => void
}

const BottomSheetDateTime = ({ date, onChange, error, onOpen = () => {} }: DatePickerPropsType) => {
  const [open, setOpen] = useState(false)
  const [dateParam, setDate] = useState<Date | null>(null)
  const [input, setInput] = useState('')

  const onChangeDate = (value: Date) => {
    setDate(value)
    setInput(dateFormat({ date: value, format: 'DD MMMM YYYY' }))
    onChange(value)
    onOpen()
    setOpen(false)
  }

  const handleOpenBottomSheet = () => {
    onOpen()
    setOpen(true)
  }

  const handleCloseBottomSheet = () => {
    onOpen()
    setOpen(false)
  }

  useEffect(() => {
    if (date) {
      setInput(dateFormat({ date, format: 'DD MMMM YYYY' }))
      setDate(date)
    }
  }, [date])

  return (
    <>
      <Fieldset handleOpen={handleOpenBottomSheet} date={input} error={error} />
      <WheelBottomSheet
        open={open}
        onClose={handleCloseBottomSheet}
        date={dateParam}
        onChange={onChangeDate}
      />
    </>
  )
}

export default BottomSheetDateTime
